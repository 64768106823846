import { ReactNode } from "react";
import { Typography, Tooltip, Progress, Button } from "./index";
import { SlInfo } from "react-icons/sl";

export type TableCell = {
  value: any;
  type: string;
  action?: (e?: any) => void;
};

export type TableRow = TableCell[];

export type TableProps = {
  titles: ReactNode[];
  values: TableRow[];
  emptyMessage?: string;
};

const getCellType = (
  cell: TableCell,
  isFirstCell: boolean,
  isLastCell: boolean,
  isLastRow: boolean,
  key: string
) => {
  switch (cell.type) {
    case "image-text":
      return (
        <td
          key={key}
          className={`px-4 mx-auto 
          ${isFirstCell ? "pl-1" : isLastCell ? "pr-1" : ""}
          ${isLastRow ? "py-2" : ""}`}
        >
          <div className="flex items-center gap-2">
            {cell.value.img ? (
              <img className="w-[30px] h-[30px]" src={cell.value.img} alt="" />
            ) : cell.value.component ? (
              cell.value.component
            ) : (
              <></>
            )}
            <Typography size="md">{cell.value.text}</Typography>
          </div>
        </td>
      );

    case "image":
      return (
        <td
          key={key}
          className={`px-4 mx-auto 
            ${isFirstCell ? "pl-1" : isLastCell ? "pr-1" : ""}
            ${isLastRow ? "py-2" : ""}`}
        >
          <div className="flex items-center justify-center w-full h-full">
            <img className="max-w-full h-[45px]" src={cell.value} alt="" />
          </div>
        </td>
      );

    case "image-link":
      return (
        <td
          key={key}
          className={`px-4 mx-auto 
              ${isFirstCell ? "pl-1" : isLastCell ? "pr-1" : ""}
              ${isLastRow ? "py-2" : ""}`}
        >
          <a target="_blank" rel="noreferrer" href={cell.value.url}>
            <div className="flex items-center justify-center w-full h-full">
              <img
                className="max-w-full h-[30px]"
                src={cell.value.img}
                alt=""
              />
            </div>
          </a>
        </td>
      );

    case "tooltip-text":
      return (
        <td
          key={key}
          className={`px-4 mx-auto 
          ${isFirstCell ? "pl-1" : isLastCell ? "pr-1" : ""}
          ${isLastRow ? "py-2" : ""}`}
        >
          <div className="flex gap-2">
            <Typography variant="tertiary">{cell.value.text}</Typography>
            <Tooltip tooltipChildren={cell.value.tooltip}>
              <SlInfo className="fill-white" size={15} />
            </Tooltip>
          </div>
        </td>
      );

    case "progress":
      return (
        <td
          key={key}
          className={`px-4 mx-auto 
          ${isFirstCell ? "pl-1" : isLastCell ? "pr-1" : ""}
          ${isLastRow ? "py-2" : ""}`}
        >
          <Progress value={cell.value.value} total={cell.value.total} />
        </td>
      );

    case "button":
      return (
        <td
          key={key}
          className={`px-4
            ${isFirstCell ? "pl-1" : isLastCell ? "pr-1" : ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          {cell.value.onClick && (
            <div className="flex items-center justify-center w-full">
              <Button
                label={cell.value.label}
                className={cell.value.className}
                disabled={cell.value.disabled}
                variant="primary"
                action={() => cell.value.onClick()}
              />
            </div>
          )}
        </td>
      );

    case "button-coming-soon":
      return (
        <td
          key={key}
          className={`px-4
              ${isFirstCell ? "pl-1" : isLastCell ? "pr-1" : ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative flex items-center justify-center w-full">
            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full cursor-progress">
              <Typography size="xs" weight="bold" className="-rotate-[20deg]">
                Coming Soon
              </Typography>
            </div>
            <Button
              label={cell.value.label}
              className="cursor-progress"
              variant="primary"
              action={() => {}}
              disabled
            />
          </div>
        </td>
      );

    default:
      return (
        <td
          key={key}
          className={`px-4 mx-auto ${
            isFirstCell ? "pl-1" : isLastCell ? "pr-1" : ""
          }
              ${isLastRow ? "py-2" : ""}`}
        >
          <div className="flex justify-center w-full">
            <Typography className="whitespace-nowrap" size="md">
              {cell.value}
            </Typography>
          </div>
        </td>
      );
  }
};

const Table = ({ titles, values, emptyMessage }: TableProps) => (
  <div className="relative w-full overflow-x-auto overflow-y-show">
    <table className="w-full">
      <thead>
        <tr>
          {titles.map((title, headIndex) => (
            <td
              key={`head${headIndex}`}
              className={`px-4 mx-auto pb-6 ${
                headIndex === 0
                  ? "pl-0"
                  : headIndex === titles.length - 1
                  ? "pr-0"
                  : ""
              }`}
            >
              <div
                className={`w-full flex ${
                  headIndex !== 0 ? "justify-center" : "justify-start"
                }`}
              >
                <Typography
                  className="uppercase whitespace-nowrap"
                  weight="semibold"
                  size="sm"
                >
                  {title}
                </Typography>
              </div>
            </td>
          ))}
        </tr>
      </thead>

      <tbody>
        {values.map((val, rowIndex) => {
          const clickableLine = val.find(
            (row) => row.type === "clickable-line"
          );

          return (
            <tr
              key={`row${rowIndex}`}
              className={`transition-colors h-[50px] ${
                clickableLine ? "hover:bg-textPrimary/5 cursor-pointer " : ""
              }`}
              onClick={() =>
                clickableLine && clickableLine.action && clickableLine.action()
              }
            >
              {(clickableLine
                ? val.filter((cell) => cell?.type !== "clickable-line")
                : val
              ).map((cellValue, cellIndex) =>
                getCellType(
                  cellValue,
                  cellIndex === 0,
                  cellIndex === titles.length - 1,
                  rowIndex === values.length - 1,
                  `cell${cellIndex}`
                )
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
    {emptyMessage && values.length === 0 && (
      <div className="flex items-center justify-center w-full py-2">
        <Typography className="h-[30px]">{emptyMessage}</Typography>
      </div>
    )}
  </div>
);

export default Table;
