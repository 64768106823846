import assert from "assert";

export function assertNotNull<T>(val: T | undefined | null, msg?: string): T {
  assert(val != null, msg);
  return val;
}

export const BACKEND_URL = assertNotNull(
  process.env.REACT_APP_BE_URL,
  "REACT_APP_BE_URL"
);

export const NETWORK_ID = parseInt(
  assertNotNull(
    process.env.REACT_APP_NETWORK_ID,
    "missing env  REACT_APP_NETWORK_ID"
  )
);

export const DAPP_DEFINITION_ADDRESS = assertNotNull(
  process.env.REACT_APP_DAPP_DEFINITION_ADDRESS,
  "missing env  REACT_APP_DAPP_DEFINITION_ADDRESS"
);

export const LENDING_MARKET_COMPONENT = assertNotNull(
  process.env.REACT_APP_LENDING_MARKET_COMPONENT,
  "missing env  REACT_APP_LENDING_MARKET_COMPONENT"
);
export const LENDING_MARKET_CDP_RESOURCE = assertNotNull(
  process.env.REACT_APP_LENDING_MARKET_CDP_RESOURCE,
  "missing env REACT_APP_LENDING_MARKET_CDP_RESOURCE"
);

export const POOL_UNIT_XRD = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_XRD,
  "missing env REACT_APP_POOL_UNIT_XRD"
);
export const POOL_UNIT_USDT = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_USDT,
  "missing env REACT_APP_POOL_UNIT_USDT"
);
export const POOL_UNIT_XWBTC = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_XWBTC,
  "missing env REACT_APP_POOL_UNIT_XWBTC"
);
export const POOL_UNIT_XETH = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_XETH,
  "missing env REACT_APP_POOL_UNIT_XETH"
);
export const POOL_UNIT_LSU = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_LSU,
  "missing env REACT_APP_POOL_UNIT_LSU"
);
export const POOL_UNIT_WOWO = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_WOWO,
  "missing env REACT_APP_POOL_UNIT_WOWO"
);
export const POOL_UNIT_EARLY = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_EARLY,
  "missing env REACT_APP_POOL_UNIT_EARLY"
);
export const POOL_UNIT_HUG = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_HUG,
  "missing env REACT_APP_POOL_UNIT_HUG"
);
export const POOL_UNIT_XUSDC = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_XUSDC,
  "missing env REACT_APP_POOL_UNIT_XUSDC"
);
export const POOL_UNIT_XPEPE = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_XPEPE,
  "missing env REACT_APP_POOL_UNIT_XPEPE"
);
export const POOL_UNIT_XLINK = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_XLINK,
  "missing env REACT_APP_POOL_UNIT_XLINK"
);

export const POOL_UNIT_XENA = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_XENA,
  "missing env REACT_APP_POOL_UNIT_XENA"
);


export const XRD = assertNotNull(
  process.env.REACT_APP_XRD,
  "missing env REACT_APP_XRD"
);
export const USDT = assertNotNull(
  process.env.REACT_APP_USDT,
  "missing env  REACT_APP_USDT"
);
export const XWBTC = assertNotNull(
  process.env.REACT_APP_XWBTC,
  "missing env REACT_APP_XWBTC"
);
export const XETH = assertNotNull(
  process.env.REACT_APP_XETH,
  "missing env REACT_APP_XETH"
);
export const HUG = assertNotNull(
  process.env.REACT_APP_HUG,
  "missing env REACT_APP_HUG"
);
export const LSU = assertNotNull(
  process.env.REACT_APP_LSU,
  "missing env REACT_APP_LSU"
);
export const WOWO = assertNotNull(
  process.env.REACT_APP_WOWO,
  "missing env REACT_APP_WOWO"
);
export const EARLY = assertNotNull(
  process.env.REACT_APP_EARLY,
  "missing env REACT_APP_EARLY"
);
export const XUSDC = assertNotNull(
  process.env.REACT_APP_XUSDC,
  "missing env  REACT_APP_XUSDC"
);
export const XPEPE = assertNotNull(
  process.env.REACT_APP_XPEPE,
  "missing env  REACT_APP_XPEPE"
);
export const XLINK = assertNotNull(
  process.env.REACT_APP_XLINK,
  "missing env  REACT_APP_XLINK"
);
export const XENA = assertNotNull(
  process.env.REACT_APP_XENA,
  "missing env  REACT_APP_XENA"
);
